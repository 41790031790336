import style from './contact.module.css'

function Contact(){

    return(
        <div className={style.contact}>
            <div>
                <h3>Adres:</h3>
                <p>32-104 Polekarcice 99</p>
                <p>GODZ. PON-PIĄTEK</p>
                <p>10-18</p>
                <p>(PO WCZEŚNIEJSZYM KONTAKCIE TEL.)</p>
            </div>
            <div>
                <h3>STRONA W&nbsp;BUDOWIE</h3>
            </div>
            <div>
                <h3>ZADZWOŃ LUB NAPISZ MAILA</h3>
                <p>TEL: <a href="tel:+48695624410">+48 695-624-410</a></p>
                <p>MAIL: <a href="mailto:promjet@promjet.pl">promjet@promjet.pl</a></p>
            </div>
        </div>
    )
}

export default Contact