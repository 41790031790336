import {NavLink, Outlet,useLocation,Navigate } from 'react-router-dom'

import style from './szycie.module.css'

import Contact from './Szycie/contact'
import MapsGoogle from './Szycie/mapsGoogle'

//promjet@
//kontakt 3 srodkowe strona w budowie
// czcionka większa
//

const Szycie = ()=>{

    const location = useLocation ()

    if(location.pathname === '/firany_zaslony_szycie_dekoracje'){
        return <Navigate to="/firany_zaslony_szycie_dekoracje/szycie_firan_i_zaslon" replace={true} />
    } 

    return(
        <div className={style.container}>
            <nav className={style.nav}>
                <div>
                    <NavLink to="/">
                        <img className={style.img} src="/logo_promjet.jpg" alt="logo Prom Jet"/>
                    </NavLink>
                </div>
                <ul> 
                    <li>
                        <NavLink 
                            to="szycie_firan_i_zaslon"
                            className={({isActive})=>isActive?'active':undefined}
                        >Szycie firan i&nbsp;zasłon</NavLink> 
                    </li>
                    <li>
                        <NavLink 
                            to="poprawki_krawieckie"
                            className={({isActive})=>isActive?'active':undefined}
                        >Poprawki krawieckie</NavLink> 
                    </li>
                    <li>
                        <NavLink 
                            to="./#contact"
                            className={({isActive})=>isActive?'active':undefined}
                        >Kontakt</NavLink> 
                    </li>
                </ul>
            </nav>
            <main className={style.main}>
                <Outlet />
            </main>
            <footer id="kontakt">
                <Contact/>
                <MapsGoogle />
            </footer>
        </div>
    )
}

export default Szycie