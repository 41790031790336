

function szycieFiranekZaslon(){
    return(
        <article id="szycie_firan_i_zaslon">
                <h1>Szycie firan i&nbsp;zasłon</h1>
                <p>
                    Jeśli szukacie Państwo sposobu upiększenia swojego wnętrza, to zapraszamy do z&nbsp;korzystania z&nbsp;naszych usług. Działamy na rynku od 2004 roku, a&nbsp;od 2011 roku współpracujemy z&nbsp;firmą Obi, szyjąc również dla ich klientów firany i&nbsp;zasłony. Lata praktyki oraz tysiące wykonanych projektów pozwalają nam zachęcić Państwa do kontaktu z&nbsp;nami i&nbsp;odwiedzenia naszej pracowni, która znajduje się 12 kilometrów od Krakowa w&nbsp;stronę Proszowic.
                    Jesteśmy otwarci na współpracę z&nbsp;projektantami oraz dekoratorami wnętrz.
                </p>
        </article>
    )
}

export default szycieFiranekZaslon