import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

import Home from './routes/home'
import Szycie from './routes/szycie'
// import Turystyka from './routes/turystyka'

import SzycieFiranZaslon from './routes/Szycie/szycieFiranZaslon';
import PoprawkiKrawieckie from './routes/Szycie/poprawkiKrawieckie'
import ScrollTotop from './components/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollTotop>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="firany_zaslony_szycie_dekoracje" element={<Szycie />}>
            <Route path="szycie_firan_i_zaslon" element={<SzycieFiranZaslon />} />
            <Route path="poprawki_krawieckie" element={<PoprawkiKrawieckie />} />
          </Route>
          {/* <Route path="turystyka" element={<Turystyka />} /> */}
          <Route
            path="*"
            element={
              <main style={{ padding: '1rem' }}>
                <p>Nie ma takiej podstrony</p>
              </main>
            }
          />
        </Routes>
      </ScrollTotop>
    </BrowserRouter>
  )
}

export default App;
