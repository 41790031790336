
import style from './mapsGoogle.module.css'

const src = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24307.187151041104!2d20.19046202941386!3d50.18024172545686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x42ef24c037dbfe42!2zNTDCsDEwJzUyLjYiTiAyMMKwMTEnMDUuNSJF!5e0!3m2!1spl!2spl!4v1656441933996!5m2!1spl!2spl"

function MapsGogle(){
    return(
        <iframe 
            title="localization"
            className={style.mapsGoogle} 
            src={src} 
            width="600" 
            height="450" 
            // style="border:0;" 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">
        </iframe>
           
    )
}

export default MapsGogle