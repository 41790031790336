
import {Link} from 'react-router-dom'
import styles from './home.module.css'

const Home = ()=>{

    return(
        <main className={styles.container}>
            <a className={styles.article} href="https://e-turysta.pl/">
                {/* <article className={styles.article}> */}
                    <div>
                        <h1>Turystyka</h1>
                    </div> 
                {/* </article> */}
            </a>
            <Link className={styles.article} to="/firany_zaslony_szycie_dekoracje">
                {/* <article className={styles.article}> */}
                    <div>
                        <h1>Firany&nbsp;/&nbsp;Zasłony&nbsp;/ Szycie&nbsp;/&nbsp;Dekoracje</h1>
                    </div>  
                {/* </article> */}
            </Link>
            <div className={styles.logo}>
                <img className={styles.img} src={'/logo_min.png'} alt="logo Prom Jet"/>
                <div className={styles.logo_text} data-text="PROM&nbsp;JET">
                    <span>PROM&nbsp;JET</span>
                </div>
            </div>
        </main>
    )
}

export default Home