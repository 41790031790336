 
import style from './poprawkiKrawieckie.module.css'

function poprawkiKrawieckie(){
    return(
        <article id="poprawki_krawieckie">
            <h1>Poprawki krawieckie</h1>
            <p>
                W&nbsp;naszej pracowni w&nbsp;ramach poprawek krawieckich wykonujemy większość czynności dotyczących przeróbek odzieży dla kobiet i&nbsp;mężczyzn.
                Najbardziej popularne to skracanie, zwężanie i&nbsp;poszerzanie:</p>
                <ul className={style.ul}>
                    <li>marynarek</li>
                    <li>spódnic</li>
                    <li>sukienek</li>
                    <li>żakietów</li>
                    <li>płaszczy</li>
                    <li>bluzek</li>
                    <li>koszul</li>
                    <li>wszywanie zamków</li>
                </ul>
            <p>Ceny ustalamy indywidualnie, w&nbsp;zależności od stopnia trudności wykonanej usługi.
            </p>
        </article>
    )
}

export default poprawkiKrawieckie